import * as React from "react";
import Navbar from "../sections/Navbar/Navbar";
import MediaPartnersLogo from "../sections/MediaPartnersLogos/MediaPartnersLogo";
import Hero from "../sections/Hero/Hero";
import RegBanner from "../sections/RegBanner/RegBanner";
import Stats from "../sections/Stats/Stats";
import DemoDay from "../sections/DemoDay/DemoDay";
import Mentors from "../sections/Mentors/Mentors";
import Timeline from "../sections/Timeline/Timeline.js";
import Video from "../sections/Video/Video";
import WhoWeWorkWith from "../sections/WhoWeWorkWith/WhoWeWorkWith";
import Media from "../sections/Media/Media";
import Footer from "../sections/Footer/Footer";
import "./global.css";
import { Helmet } from "react-helmet";
import "@progress/kendo-theme-default/dist/all.css";
import { ChakraProvider } from "@chakra-ui/react";
import InvestorsPartners from "../sections/InvestorPartners/InvestorPartners";
import JotFormReact from 'jotform-react';
import "../fonts/stylesheet.css";

import { Widget } from '@typeform/embed-react'

// styles
const text = {
  fontFamily: "Manrope",
};

// markup
const PreRegPage = () => {

  const handleSubmit = () => {
    window.lintrk('track', { conversion_id: 13765001 });
    
  };

  return (
    <main style={text}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Conquest: India's First and Largest Student-run Startup Accelerator</title>
      </Helmet>
      <Navbar />

     {/* <center><h1> Registrations Opening Soon!</h1></center> */}
     
    <center> <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScCIwlnAiHjXE91EyW0SuenTqmMkOI22JuFj5PsetrAota4iQ/viewform?embedded=true" width="700" height="1400" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </center>
    {/* <Widget id="eucCnd6W" style={{ width: '100%' , height: '700px' }} className="my-form" /> */}

      <Footer />
    </main>
  );
};

export default PreRegPage;
